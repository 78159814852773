$app-journal-overview--background-color: #eff6fb;
$app-journal-overview--border-color: $t-a-palette-default-blue-light;
$app-journal-overview--border-radius: 12px;
$app-journal-overview--font-weight-bold: $t-a-typography-font-weight-bold;
$app-journal-overview--font-weight-normal: $t-a-typography-font-weight-normal;
$app-journal-overview--metric-border-color: $t-a-palette-default-greyscale-500;
$app-journal-overview--metric-icon-container-size: 48px;
$app-journal-overview--metric-icon-size: 24px;
$app-journal-overview--metric-line-height: $t-a-typography-line-height-tight;

.app-journal-overview {
	padding: spacing(24) spacing(16);
	background-color: $app-journal-overview--background-color;
	margin: 0 -16px;
	font-size: $t-a-typography-font-size-s;
	font-family: $t-a-typography-font-family-default;
	line-height: $t-a-typography-line-height-md;
}

@include from-tablet {
	.app-journal-overview {
		padding: spacing(24);
		border-radius: $app-journal-overview--border-radius;
		margin: 0;
	}

	.feature-flag-journal_page_improvements_part_2 .app-journal-overview {
		padding: $t-a-space-absolute-1200 0 $t-a-space-absolute-1600 0;
	}
}

.app-journal-overview > * {
	margin: 0;
	padding: 0;
}

.app-journal-overview > * + * {
	padding: spacing(16) 0 0;
	margin: spacing(16) 0 0;
	border-top: 1px solid $app-journal-overview--border-color;
}

.app-journal-overview__metrics {
	font-family: $t-a-typography-font-family-default;

	dl {
		margin: 0;
	}
}

@include from-tablet {
	.app-journal-overview li::before {
		margin-right: 12px;
	}

	.app-journal-overview__metrics {
		display: flex;
		flex-wrap: wrap;
	}
}

.app-journal-overview__promo-text li p {
	margin: 0;
	display: inline;
}

.feature-flag-journal_page_improvements_part_2 .app-journal-overview__editors-container {
	padding: $t-a-space-absolute-600 0;
	margin: $t-a-space-absolute-600 0;
	border-bottom: 1px solid $app-journal-overview--border-color;
	border-top: 1px solid $app-journal-overview--border-color;
}

.app-journal-overview__metric {
	display: flex;
	flex: 1 0 100%;
	align-items: center;
	margin-bottom: spacing(16);
	line-height: $app-journal-overview--metric-line-height;
}

.app-journal-overview__metric:last-of-type {
	margin-bottom: 0;
}

@include from-tablet {
	.app-journal-overview__metric {
		flex-basis: 50%;
		margin: 0;
	}

	.app-journal-overview__metric:nth-of-type(n + 3) {
		margin-top: spacing(16);
	}
}

.app-journal-overview__metric-label,
.app-journal-overview__metric-value {
	margin: 0;
}

.app-journal-overview__metric-value {
	font-weight: $app-journal-overview--font-weight-bold;
}

.app-journal-overview__metric-label {
	font-weight: $app-journal-overview--font-weight-normal;
	font-size: $t-a-typography-font-size-xs;
}

.app-journal-overview__metric-icon-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: $app-journal-overview--metric-icon-container-size;
	height: $app-journal-overview--metric-icon-container-size;
	flex-shrink: 0;
	border-radius: 50%;
	border: 1px solid $app-journal-overview--metric-border-color;
	margin-right: spacing(16);
}

.app-journal-overview__metric-icon {
	display: inline-block;
	width: $app-journal-overview--metric-icon-size;
	height: $app-journal-overview--metric-icon-size;
}

.app-journal-overview__metric circle {
	fill: color('black');
}

.app-journal-overview__editor > * {
	margin: 0;
}

.app-journal-overview__editor {
	margin: 0;
	font-family: $t-a-typography-font-family-default;

	dt {
		font-weight: $app-journal-overview--font-weight-bold;
	}
}

.app-journal-overview-new-data-system__editor {
	column-count: 1;
}

@include from-tablet {
	.app-journal-overview__editor-list:has(.app-journal-overview__editor-list-item:nth-child(n+3)) {
		column-count: 2;
	}
}

.app-journal-overview__editor-list-item {
	break-inside: avoid;
}

.app-journal-overview__editor-list {
	@include u-list-reset;
}

.app-journal-overview__view-all-editors {
	border-top: 0;
	padding: 0;
	margin-top: $t-a-space-absolute-400;

	svg {
		display: inline-block;
		vertical-align: middle;
		fill: $t-a-palette-primary-medium-blue;
	}
}

