.app-jflow-content-page {
	.float--left {
		float: left;
		margin-right: $t-a-space-absolute-400;
		margin-bottom: $t-a-space-absolute-400;
	}

	.float--right {
		float: right;
		margin-left: $t-a-space-absolute-400;
		margin-bottom: $t-a-space-absolute-400;
	}

	img {
		border-radius: 10px;
	}

	p img.float--right,
	p img.float--left,
	p img.float--none,
	p img {
		height: auto;
		margin-right: $t-a-space-absolute-400;
	}

	@include from-tablet {

		p img {
			height: auto;
		}

		p img.float--right,
		p img.float--left {
			width: auto;
			max-width: 50%;
		}

		p:has(> img)::after {
			content: ".";
			visibility: hidden;
			display: block;
			height: 0;
			clear: left;
		}
	}
}
