.app-journal-editorial__editor-list {
	@include u-list-reset;
	margin: 0 0 spacing(32) 0;
}

.app-journal-editorial-list__item {
	padding: 0;
	font-size: $t-a-typography-font-size-default;
	margin: spacing(8) 0 spacing(16) 0;
	font-family: $context--font-family-serif;
	line-height: spacing(32);
}
