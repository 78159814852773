// Allows for this to be used in applications on HTML that the consumer does not control e.g. from CMS
@mixin eds-c-section-heading {
	border-bottom: 1px solid $t-a-palette-default-blue-light;
	padding-bottom: spacing(8);
	width: 100%;
}

.eds-c-section-heading {
	@include eds-c-section-heading;
}

.eds-c-section-heading--darker-underline {
	border-color: $t-a-border-color-primary;
}

.eds-c-section-heading--aside {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
}
