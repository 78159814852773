$avatar-card--font-size-xs: $t-a-typography-font-size-xs;
$avatar-card--font-size-md: $t-a-typography-font-size-default;
$avatar-card--font-size-s: $t-a-typography-font-size-s;
$avatar-card--gutter-m: $t-a-space-absolute-400;
$avatar-card--gutter-l: $t-a-space-absolute-600;
$avatar-card--gutter-s: $t-a-space-absolute-200;
$avatar-card--icon-size: 2rem;
$avatar-card--img-size: 64px;
$avatar-card--img-size-l: 80px;

.app-avatar-card {
	display: flex;
	flex-direction: column;
	justify-items: flex-start;
	row-gap: 8px;
	width: 100%;
	background-color: $t-a-palette-default-white;
	padding: $avatar-card--gutter-m 0;
	border-top: 1px solid #c5e0f4;
	border-bottom: 1px solid #c5e0f4;
}

.app-avatar-card + .app-avatar-card {
	border-top: none;
}

.app-avatar-card .app-avatar-card__image,
.app-avatar-card .app-avatar-card__avatar {
	grid-area: picture;
}

.app-avatar-card__heading,
.app-show-more-show-less {
	grid-area: name;
}

.app-avatar-card__details {
	grid-area: details;
	grid-column-start: 2;

	a {
		display: block;
		color: $t-a-palette-default-greyscale-800;
		line-height: $t-l-line-height-md;
		line-break: anywhere;
	}

	a + a {
		margin-top: $avatar-card--gutter-s;
	}
}

.app-avatar-card > .app-avatar-card__image,
.app-avatar-card > .app-avatar-card__avatar {
	flex-shrink: 0;
}

.app-avatar-card__image {
	width: $avatar-card--img-size;
	height: $avatar-card--img-size;
	object-fit: cover;
	border-radius: 50%;
}

.app-avatar-card__avatar {
	display: flex;
	justify-content: center;
	align-items: center;
	width: $avatar-card--img-size;
	height: $avatar-card--img-size;
	background-color: $t-l-color-blue-200;
	border-radius: 50%;
	position: relative;
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0iIzY2NjY2NiIgaWQ9ImVkcy1pLXVzZXItc2luZ2xlLW1lZGl1bSI+PHBhdGggZD0iTTEyIDFhNSA1IDAgMSAxIDAgMTAgNSA1IDAgMCAxIDAtMTBabTAgMmEzIDMgMCAxIDAgMCA2IDMgMyAwIDAgMCAwLTZabS0uNDA2IDkuMDA4YTguOTY1IDguOTY1IDAgMCAxIDYuNTk2IDIuNDk0QTkuMTYxIDkuMTYxIDAgMCAxIDIxIDIxLjAyNVYyMmExIDEgMCAwIDEtMSAxSDRhMSAxIDAgMCAxLTEtMXYtLjk4NWMuMDUtNC44MjUgMy44MTUtOC43NzcgOC41OTQtOS4wMDdabS4zOSAxLjk5Mi0uMjk5LjAwNmMtMy42My4xNzUtNi41MTggMy4xMjctNi42NzggNi43NzVMNSAyMWgxMy45OThsLS4wMDktLjI2OGE3LjE1NyA3LjE1NyAwIDAgMC0xLjk3LTQuNTczbC0uMjE0LS4yMTNBNi45NjcgNi45NjcgMCAwIDAgMTEuOTg0IDE0WiIvPjwvc3ZnPgo=");
	background-position: 50% 50%;
	background-size: 60%;
	background-repeat: no-repeat;
}

/*.app-avatar-card__avatar {
	background-color: #f0f7fc;
	border-radius: 50%;
}*/

.app-avatar-card__list,
.app-avatar-card__details-list {
	@include u-list-reset;
}

.app-avatar-card__text-container p {
	font-size: clamp(0.875rem, 4vw, 1rem);
	margin-bottom: 0;
}

.app-avatar-card__description {
	font-size: clamp(0.875rem, 4vw, 1rem);
	margin: $avatar-card--gutter-s 0 0 0;
}

.app-avatar-card__role {
	font-size: clamp(0.875rem, 4vw, 1rem);
	margin: $avatar-card--gutter-s 0 0 0;
}

.app-avatar-card__details-list {
	@include u-list-reset;

	grid-area: details;
	display: none;
	border-left: 8px solid $t-a-palette-default-blue-light;
	font-size: $t-a-typography-font-size-xs;
	padding-left: $avatar-card--gutter-s;
	margin-top: $t-a-space-absolute-600;

	.u-text-s {
		font-size: $avatar-card--font-size-xs;
	}
}

.app-avatar-card__details-list li {
	margin-bottom: $t-a-space-absolute-400;
}

.app-avatar-card__details-list ul {
	@include u-list-reset;
}

.app-avatar-card__details-list ul li {
	margin: $t-a-space-absolute-200;
}

.app-avatar-card__details-list li:last-child {
	margin-bottom: 0;
}

button.app-show-more-show-less--expanded + .app-avatar-card__details-list {
	display: block;
}

.app-avatar-card .app-show-more-show-less span {
	font-size: $t-a-typography-font-size-xs;
}

.app-avatar-card__heading {
	font-size: $t-a-typography-font-size-s;
	line-height: $t-l-line-height-tight;
	margin: 0;
}

//Add a margin so that the logo aligns with the text at the top
.app-eb-orcid-icon {
	margin-top: 3px;
}

@include from-tablet {
	.app-avatar-card {
		display: inline-grid;
		grid-template-columns: 64px auto;
		grid-template-rows: 1fr;
		/* stylelint-disable declaration-colon-space-after */
		/* stylelint-disable indentation */
		grid-template-areas:
			"picture name";
		/* stylelint-enable declaration-colon-space-after */
		/* stylelint-enable indentation */
		gap: 8px 16px;
		font-size: $avatar-card--font-size-s;
		padding: $avatar-card--gutter-l 0;

		&--with-details {
			/* stylelint-disable declaration-colon-space-after */
			/* stylelint-disable indentation */
			grid-template-areas:
		"picture name"
		"picture details";
			/* stylelint-enable declaration-colon-space-after */
			/* stylelint-enable indentation */
		}
	}

	.app-avatar-card__heading {
		font-size: $t-l-font-size-450;

		&--center {
			align-self: center;
		}
	}

	.app-avatar-card__description.show-more-show-less {
		-webkit-line-clamp: 2;
		line-clamp: 2;
	}

	.app-avatar-card .app-show-more-show-less span {
		font-size: $t-a-typography-font-size-s;
	}

	.app-avatar-card__details-list {
		font-size: $t-a-typography-font-size-s;
		padding-left: $avatar-card--gutter-m;

		.u-text-s {
			font-size: initial;
		}
	}

	.app-avatar-card__details-list li {
		margin-bottom: $t-a-space-absolute-600;
	}
}

@include from-desktop {
	ul li:first-child .app-avatar-card {
		margin-top: 0;
	}

	.app-avatar-card__details.app-show-more-show-less--expanded {
		font-size: $t-a-typography-font-size-s;
	}
}
