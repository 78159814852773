.c-status-message {
	font-family: $status-message--font-family;
	font-size: clamp($t-a-typography-font-size-xs, 4vw, $t-a-typography-font-size-s);
	font-weight: $t-a-typography-font-weight-normal;
	position: relative;

	*:last-child {
		margin-bottom: spacing(0);
	}

	a {
		color: $t-a-palette-default-black;
	}
}

.c-status-message--bold {
	font-weight: $t-a-typography-font-weight-bold;
}

.c-status-message--boxed {
	background-color: $status-message--background-color;
	border: $status-message--border;
	border-radius: $status-message--border-radius;
	line-height: $status-message--line-height;
	padding: $status-message--gutter;
	box-shadow: 0 0 5px 0 rgba(51, 51, 51, 0.1);
	overflow: hidden;
}

.c-status-message--banner {
	background-color: $status-message--background-color;
	line-height: $status-message--line-height;
	padding: $status-message--gutter 0 $status-message--gutter 0;
}

.c-status-message--banner .c-status-message__container {
	justify-content: center;
	padding: 0 spacing(16);
}

.c-status-message--sticky {
	position: sticky;
	top: 0;
	z-index: 999;
}

.c-status-message__container {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.c-status-message__icon {
	width: $status-message--icon-size;
	height: $status-message--icon-size;
	margin-right: $status-message--gutter-icon;
	flex-shrink: 0;
	align-self: flex-start;
}

.c-status-message__message :first-child,
.c-status-message__message :last-child {
	margin-top: 0;
}

.c-status-message__icon--top {
	align-self: flex-start;
}

@each $type, $value in $status-message-theme {
	.c-status-message--#{$type} {
		.c-status-message__icon {
			color: $value;
		}
	}

	.c-status-message--banner.c-status-message--#{$type} {
		border-bottom: $status-message--boxed-banner solid $value;
	}

	.c-status-message--boxed.c-status-message--#{$type} .c-status-message__bottom-border {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		background: $value;
		height: 4px;
		width: 100%;
	}

	.c-status-message--#{$type} .c-status-message__icon {
		fill: $value;
	}
}


