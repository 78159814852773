.app-journal-nav {
	margin: spacing(8) 0 spacing(32);
}

.app-journal-nav .eds-c-button {
	padding: spacing(8) spacing(24);
}

.app-journal-nav__item--hide-mobile {
	display: none;
}

.app-journal-nav__item--hide-desktop {
	display: inline-block;
}

@include from-tablet {
	.app-journal-nav {
		margin: spacing(8) spacing(8) spacing(48) 0;
	}

	.app-journal-nav__item--hide-mobile {
		display: inline-block;
	}

	.app-journal-nav__item--hide-desktop {
		display: none;
	}
}

.app-journal-nav__list {
	@include u-list-reset;
	display: flex;
	flex-wrap: wrap;
	text-align: center;
	gap: spacing(16);
}

.app-journal-nav__item {
	flex: 1 0 100%;
}

@include from-tablet {
	.app-journal-nav__item {
		flex: 0 0 auto;
	}
}
