$app-navigation--menu-item-color: $t-a-palette-default-greyscale-800;
$app-navigation--active-color: $t-a-palette-default-blue-medium-700;
$app-navigation--bottom-border: 2px solid $t-a-palette-default-blue-light;
$app-navigation--font-family: $t-a-typography-font-family-default;
$app-navigation--font-size: $t-a-typography-font-size-xs;
$app-navigation--font-weight: $t-a-typography-font-weight-bold;
$app-navigation--icon-color-default: $t-a-palette-default-greyscale-600;
$app-navigation--icon-color-open: currentColor;
$app-navigation--icon-color: $t-a-palette-default-greyscale-600;
$app-navigation--icon-margin-left-100: $t-a-space-relative-100;
$app-navigation--icon-margin-left-200: $t-a-space-relative-200;
$app-navigation--icon-size: $t-a-icon-small-width;
$app-navigation--icon-transform-rotate: rotate(180deg);
$app-navigation--icon-transition: all 0.2s;
$app-navigation--menu-items-gap: $t-a-space-relative-400;
$app-navigation--menu-items-padding: $t-a-space-relative-400 $t-a-space-relative-200;
$app-navigation--padding: $t-a-space-relative-200 $t-a-space-relative-400;
$app-navigation--pill-bg-color: $t-a-palette-default-blue-light-400;
$app-navigation--pill-border-radius: $t-a-border-radius-200;
$app-navigation--pill-bottom-position: -8px;
$app-navigation--pill-height: 6px;
$app-navigation--dropdown-padding: $t-a-space-relative-400 $t-a-space-relative-600;
$app-navigation--background-color: $t-a-palette-default-white;
$app-navigation--dropdown-group-padding: $t-a-space-absolute-400;
$app-navigation--dropdown-gap: $t-a-space-relative-800;
$app-navigation--dropdown-shadow: 0 4px 12px 0 rgba(1, 50, 75, 0.1);
$app-navigation--dropdown-border: 1px solid $t-l-color-blue-300;
$app-navigation--dropdown-border-open: 1px solid $t-l-color-blue-300;
$app-navigation--dropdown-border-radius: $t-a-border-radius-200;
$app-navigation--list-font-size: $t-a-typography-font-size-xs;
$app-navigation--list-font-weight: $t-a-typography-font-weight-normal;
$app-navigation--list-color: $t-a-palette-default-greyscale-800;
$app-navigation--list-line-height: $t-l-line-height-base;
$app-navigation--list-link-background-color: $t-l-color-blue-600;
$app-navigation--list-link-active-background-color: $t-a-palette-default-blue-lighter;
$app-navigation--list-link-active-icon-color: $t-a-palette-default-greyscale-700;
$app-navigation--list-link-text-color: $t-a-palette-default-white;
