$t-app-collections-list-color: $t-a-palette-default-greyscale-800;
$t-app-collections-list-font-size: $t-a-typography-font-size-xs;
$t-app-collections-list-gap: $t-a-space-absolute-200;
$t-app-collections-list-icon-background-color: $t-a-palette-default-blue-lighter;
$t-app-collections-list-icon-container-size: 40px;
$t-app-collections-list-icon-size: 20px;
$t-app-collections-list-line-height: $t-a-typography-line-height-tight;
$t-app-collections-list-label-font-weight: $t-a-typography-font-weight-bold;
$t-app-collections-list-list-container-margin-top: calc($t-app-collections-list-font-size * $t-app-collections-list-line-height / 2);
$t-app-collections-list-list-item-gap: $t-a-space-absolute-200;


.app-collections-list {
	display: flex;
	gap: $t-app-collections-list-gap;
	font-size: $t-app-collections-list-font-size;
	line-height: $t-app-collections-list-line-height;
}

.app-collections-list__icon-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	width: $t-app-collections-list-icon-container-size;
	height: $t-app-collections-list-icon-container-size;
	border-radius: 50%;
	background-color: $t-app-collections-list-icon-background-color;
}

.app-collections-list__icon {
	fill: $t-app-collections-list-color;
	width: $t-app-collections-list-icon-size;
	height: $t-app-collections-list-icon-size;
}

.app-collections-list__label {
	font-weight: $t-app-collections-list-label-font-weight;
	flex-shrink: 0;
}

.app-collections-list__list-container {
	display: flex;
	flex-direction: column;
	margin-top: $t-app-collections-list-list-container-margin-top;
}

.app-collections-list__list {
	list-style: none;
	margin: 0;
	padding: 0;
}

.app-collections-list__divider {
	display: none;
}

.app-collections-list__list-item {
	margin-top: $t-app-collections-list-list-item-gap;
}

.app-collections-list__link {
	display: block;
	color: $t-app-collections-list-color;
}

.app-collections-list__link:visited {
	color: $t-app-collections-list-color;
}
