// explicit width and height are defined for images in this stylesheet for web performance reasons to minimise CLS (cumulative layout shift, see: https://web.dev/articles/cls)
.app-language-checker-sidebar-card .app-card-highlight--stack img {
	width: 100%;
	aspect-ratio: 3 / 2;
	object-fit: cover;
}

@include from-tablet {
	.app-language-checker-sidebar-card .app-card-highlight--stack img {
		width: 200px;
		height: 100px;
		aspect-ratio: 2 / 1;
	}
}

@include from-desktop {
	.app-language-checker-sidebar-card .app-card-highlight--stack img {
		width: 300px;
		height: 200px;
		aspect-ratio: 3 / 2;
	}
}
