.app-journal-filter-by-sort-by {
	background-color: #eff6fb;
	font-family: $t-a-typography-font-family-default;
	padding: $t-a-space-absolute-400 $t-a-space-absolute-400 $t-a-space-absolute-600;
	margin: 0 -16px;

	// reset user agent legend styling
	legend {
		padding: 0;
	}

	// reset user agent fieldset styling
	fieldset {
		border: 0;
		margin: 0;
		padding: 0;
	}
}

.app-journal-filter-by-sort-by fieldset {
	margin-top: $t-a-space-absolute-600;
}

.app-journal-filter-by-sort-by__filter-by-container {
	border-bottom: 1px solid #cedbe0;
	padding-bottom: $t-a-space-absolute-600;
	margin-bottom: $t-a-space-absolute-400;
}

.app-journal-filter-by-sort-by legend {
	font-size: $t-a-typography-font-size-s;
	font-weight: $t-a-typography-font-weight-bold;
	line-height: $t-a-typography-line-height-tighter;
	margin-bottom: $t-a-space-absolute-200;
}

.app-journal-filter-by-sort-by label {
	font-size: $t-a-typography-font-size-xs;
}

.app-journal-filter-by-sort-by__fieldset-label {
	display: block;
	font-size: $t-a-typography-font-size-xs;
	font-weight: $t-a-typography-font-weight-bold;
}

.app-journal-filter-by-sort-by input[type="radio"] {
	margin-right: $t-a-space-absolute-200;
}

.app-journal-filter-by-sort-by__radios {
	display: flex;

	& label:first-of-type {
		margin-right: $t-a-space-absolute-800;
	}
}

.app-journal-filter-by-sort-by__select-container {
	position: relative;
}


.app-journal-filter-by-sort-by__select-container select {
	-moz-appearance: none;
	-webkit-appearance: none;
	border: 1px solid #000;
	color: #000;
	width: 100%;
	padding: 0.8rem 0.5rem;
	background-color: #fff;
	font-weight: $t-a-typography-font-weight-normal;
	text-align: left;
	border-radius: 3px;
	font-size: $t-a-typography-font-size-xs;

	&:hover,
	&:focus {
		color: #000;
		background-color: #fff;
	}

	option {
		color: $t-a-palette-primary-medium-blue;
		background-color: #fff;
		background-image: none;
		border: 2px solid $t-a-palette-primary-medium-blue;
		box-shadow: #fff;
	}
}

.app-journal-filter-by-sort-by__select-container svg {
	position: absolute;
	right: 1.2rem;
	top: 50%;
	transform: translateY(-50%);
	pointer-events: none;
}

.app-journal-filter-by-sort-by .eds-c-button {
	margin-top: $t-a-space-absolute-600;
}

.app-journal-filter-by-sort-by__publishing-model {
	margin-top: $t-a-space-absolute-400;
}

.app-journal-filter-by-sort-by__fieldset-inner {
	margin-top: $t-a-space-absolute-400;
}


@media only screen and (min-width: 532px) {
	.app-journal-filter-by-sort-by {
		display: flex;
		justify-content: space-between;
		border-radius: 12px;
		margin: 0;
		padding: $t-a-space-absolute-600;
	}

	.app-journal-filter-by-sort-by__flex-direction-column {
		flex-direction: column;
	}

	.app-journal-filter-by-sort-by__select-container {
		width: 190px;
	}

	.app-journal-filter-by-sort-by select {
		width: 190px;
	}

	.app-journal-filter-by-sort-by button {
		margin-top: 0;
		align-self: flex-end;
	}

	.app-journal-filter-by-sort-by fieldset {
		margin: 0;
		height: 100%;
	}

	.app-journal-filter-by-sort-by__form-controls {
		line-height: 45px; // hack to center align text across all three horizontol sections (filter by, sort by, button)
	}

	.app-journal-filter-by-sort-by__fieldset-label {
		line-height: $t-a-typography-line-height-tight;
	}

	.app-journal-filter-by-sort-by__radios label:first-of-type {
		margin-right: $t-a-space-absolute-600;
	}

	.app-journal-filter-by-sort-by legend {
		margin-bottom: $t-a-space-absolute-400;
	}

	.app-journal-filter-by-sort-by__separator {
		height: auto;
		border-right: 1px solid #cedbe0;
		margin-right: $t-a-space-absolute-200;
		padding-left: $t-a-space-absolute-200;
	}

	.app-journal-filter-by-sort-by__filter-by-container,
	.app-journal-filter-by-sort-by__sort-by-container {
		flex-shrink: 0;
	}

	.app-journal-filter-by-sort-by__fieldset-inner {
		margin-top: 0;
	}
}

@media only screen and (min-width: 532px) and (max-width: 732px) {
	.app-journal-filter-by-sort-by__sort-by-container + .app-journal-filter-by-sort-by__separator {
		display: none;
	}

	.app-journal-filter-by-sort-by {
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	.app-journal-filter-by-sort-by .eds-c-button {
		margin-top: $t-a-space-absolute-400;
	}

	.app-journal-filter-by-sort-by__separator {
		margin-right: $t-a-space-absolute-400;
		padding-left: $t-a-space-absolute-400;
	}

	.app-journal-filter-by-sort-by__publishing-model {
		margin-top: $t-a-space-absolute-400;
	}
}

@include from-desktop {
	.app-journal-filter-by-sort-by__fieldset-inner {
		height: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.app-journal-filter-by-sort-by__form-controls {
		display: flex;
		justify-content: space-between;
	}

	.app-journal-filter-by-sort-by__filter-by-controls-container {
		display: flex;
		flex-direction: row;

		.app-journal-filter-by-sort-by__form-controls {
			margin-right: $t-l-spacing-relative-800;
			margin-top: 0;
		}
	}

	.app-journal-filter-by-sort-by__fieldset-label {
		margin-right: $t-a-space-absolute-200;
		align-self: center;
	}

	.app-journal-filter-by-sort-by__radios label:first-of-type {
		margin-right: $t-a-space-absolute-800;
	}
}

@media only screen and (min-width: 1024px) and (max-width: 1245px) {
	.app-journal-filter-by-sort-by__sort-by-container + .app-journal-filter-by-sort-by__separator {
		display: none;
	}

	.app-journal-filter-by-sort-by {
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	.app-journal-filter-by-sort-by .eds-c-button {
		margin-top: $t-a-space-absolute-400;
	}

}

@media only screen and (min-width: 1100px) {
	.app-journal-filter-by-sort-by {
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	.app-journal-filter-by-sort-by__sort-by-container + .app-journal-filter-by-sort-by__separator {
		display: none;
	}

	.app-journal-filter-by-sort-by__radios {
		margin-right: 16px;
	}

	.app-journal-filter-by-sort-by button {
		margin-left: auto;
	}

	.app-journal-filter-by-sort-by__form-controls.app-journal-filter-by-sort-by__publishing-model {
		margin-right: 0;
	}

	.app-journal-filter-by-sort-by__separator {
		margin-right: 16px;
		padding-left: 16px;
	}

}

@media only screen and (min-width: 532px) and (max-width: 1099px) {
	.app-journal-filter-by-sort-by__radios {
		display: flex;
	}

	.app-journal-filter-by-sort-by {
		display: flex;
		justify-content: space-between;
		border-radius: 12px;
		margin: 0;
		padding: $t-a-space-absolute-600;
	}

	.app-journal-filter-by-sort-by__flex-direction-column {
		flex-direction: column;
	}

	.app-journal-filter-by-sort-by__select-container {
		width: 190px;
	}

	.app-journal-filter-by-sort-by select {
		width: 190px;
	}

	.app-journal-filter-by-sort-by button {
		align-self: flex-end;
	}

	.app-journal-filter-by-sort-by fieldset {
		margin: 0;
		height: 100%;
	}

	.app-journal-filter-by-sort-by__form-controls {
		line-height: 45px; // hack to center align text across all three horizontol sections (filter by, sort by, button)
	}

	.app-journal-filter-by-sort-by__fieldset-label {
		line-height: $t-a-typography-line-height-tight;
	}

	.app-journal-filter-by-sort-by__radios label:first-of-type {
		margin-right: $t-a-space-absolute-600;
	}

	.app-journal-filter-by-sort-by legend {
		margin-bottom: $t-a-space-absolute-400;
	}

	.app-journal-filter-by-sort-by__separator {
		height: auto;
		border-right: 1px solid #cedbe0;
		margin-right: $t-a-space-absolute-200;
		padding-left: $t-a-space-absolute-200;
	}

	.app-journal-filter-by-sort-by__filter-by-container,
	.app-journal-filter-by-sort-by__sort-by-container {
		flex-shrink: 0;
	}

	.app-journal-filter-by-sort-by__fieldset-inner {
		margin-top: 0;
	}

	.app-journal-filter-by-sort-by__sort-by-container + .app-journal-filter-by-sort-by__separator {
		display: none;
	}
}

@media only screen and (min-width: 1099px) and (max-width: 1244px) {
	.app-journal-filter-by-sort-by .app-journal-filter-by-sort-by__separator {
		border-color: transparent;
	}
	.app-journal-filter-by-sort-by__fieldset-inner {
		margin-bottom: 15px;
	}

	// When filter has publishing model it occupies more space so we need a tricky temporary fix
	.app-journal-filter-by-sort-by.app-journal-filter-by-sort-by__flex-direction-column:has(.app-journal-filter-by-sort-by__form-controls.app-journal-filter-by-sort-by__publishing-model) {
		.app-journal-filter-by-sort-by__fieldset-inner {
			flex-direction: column;
		}

		.app-journal-filter-by-sort-by__form-controls {
			display: block;
		}
	}
}
