.app-about-this-journal {
	font-size: $t-a-typography-font-size-xs;
}

.app-about-this-journal a {
	color: inherit;
}

.app-about-this-journal a:visited {
	color: inherit;
}

@include from-tablet {
	.app-about-this-journal {
		font-size: $t-a-typography-font-size-s;
	}
}

.app-about-this-journal .c-list-description,
.app-about-this-journal .c-list-columned {
	font-size: $t-a-typography-font-size-xs;
}

.app-about-this-journal .c-list-description__item {
	margin-bottom: spacing(32);
}

.app-about-this-journal .c-list-description__item:last-child {
	margin-bottom: 0;
}

@include from-tablet {
	.app-about-this-journal .c-list-description,
	.app-about-this-journal .c-list-columned {
		font-size: $t-a-typography-font-size-s;
	}

	.app-about-this-journal .c-list-description__item {
		margin-bottom: spacing(48);
	}
}
