@use "sass:list";
// explicit width and height are defined for images in this stylesheet for web performance reasons to minimise CLS (cumulative layout shift, see: https://web.dev/articles/cls)

$card-highlight--author-font-size: $t-a-typography-font-size-s;
$card-highlight--background-color: $t-a-palette-default-blue-lighter;
$card-highlight--border-color: #3c9cd7;
$card-highlight--border-radius: 8px;
$card-highlight--border-width-style: 1px solid;
$card-highlight--border: $card-highlight--border-width-style $card-highlight--border-color;
$card-highlight--content-font-size: $t-a-typography-font-size-xs;
$card-highlight--content-line-height: 1.7;
$card-highlight--cta-color: $t-a-palette-primary-medium-blue;
$card-highlight--cta-icon-size: 16px;
$card-highlight--default--title-color: $t-a-palette-default-black;
$card-highlight--font-family: $t-a-typography-font-family-default;
$card-highlight--heading-font-size: $t-a-typography-font-size-default;
$card-highlight--heading-line-height: $t-a-typography-line-height-base;
$card-highlight--heading-link-line-height: $t-a-typography-line-height-md;
$card-highlight--heading-margin-bottom: $t-a-space-absolute-200;
$card-highlight--highlight-border-width-style: 10px solid;
$card-highlight--image-border: $card-highlight--border-width-style #cfd8dc;
$card-highlight--image-desktop-width: 256px;
$card-highlight--image-desktop-height: 144px;
$card-highlight--image-in-content-max-width: 40%;
$card-highlight--image-aspect-ratio: list.slash(16, 9);
$card-highlight--icon-size: 72px;
$card-highlight--spacing: $t-a-space-absolute-400;

.app-card-highlight {
	position: relative;
	display: flex;
	padding: $card-highlight--spacing;
	background-color: $card-highlight--background-color;
	border-top: $card-highlight--highlight-border-width-style $card-highlight--border-color;
	border-radius: $card-highlight--border-radius;
	overflow: hidden;
	flex-direction: column-reverse;
	justify-content: space-between;
	font-family: $card-highlight--font-family;
}

.app-card-highlight__main {
	display: flex;
	flex: 1 1 auto;
	word-break: break-word;
	font-size: $card-highlight--content-font-size;
}

.app-card-highlight__content {
	font-weight: $t-a-typography-font-weight-normal;
}

.app-card-highlight__text {
	line-height: $card-highlight--content-line-height;
}

.app-card-highlight__text p {
	margin: 0;
}

.app-card-highlight__text ul {
	margin: 0;
}

.app-card-highlight__text + .app-card-highlight__cta {
	display: inline-block;
	margin-top: $t-a-space-absolute-200;
}

.app-card-highlight__cta {
	font-weight: $t-a-typography-font-weight-bold;
	color: $card-highlight--cta-color;

	//TODO - Remove this cta color when links scss from EDS is updated with hover color
	&:hover,
	&:focus {
		color: $card-highlight--cta-color;
	}
}

.app-card-highlight__cta-icon {
	display: inline-block;
	vertical-align: middle;
	width: $card-highlight--cta-icon-size;
	height: $card-highlight--cta-icon-size;
	fill: currentColor;
}

.app-card-highlight__heading {
	margin-top: 0;
	margin-bottom: $card-highlight--heading-margin-bottom;
	font-size: $card-highlight--heading-font-size;
}

.app-card-highlight__heading a {
	color: $card-highlight--default--title-color;
	line-height: $card-highlight--heading-link-line-height;
}

.app-card-highlight .app-card-highlight__side {
	flex: 1 0 100%;
	margin: -16px -16px $card-highlight--spacing -16px;
}

.app-card-highlight .app-card-highlight__side img {
	width: 100%;
	height: auto;
}

.app-card-highlight .app-card-highlight__text img {
	width: auto;
	max-width: 100%;
}

.app-card-highlight .app-card-highlight__text img.float--right {
	float: right;
}

.app-card-highlight .app-card-highlight__text img.float--right {
	float: left;
}

.app-card-highlight__icon {
	fill: $t-a-palette-primary-medium-blue;
	width: $card-highlight--icon-size;
	height: $card-highlight--icon-size;
}

@include from-tablet {
	.app-card-highlight {
		flex-direction: row;
		border-left: $card-highlight--highlight-border-width-style $card-highlight--border-color;
		border-top: none;
	}

	.app-card-highlight__content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.app-card-highlight .app-card-highlight__side {
		flex: 0 0 auto;
		align-self: center;
		margin: -16px -16px -16px $card-highlight--spacing;
	}

	.app-card-highlight__heading {
		margin-bottom: 0;
	}

	.app-card-highlight .app-card-highlight__side img {
		border-radius: 0 12px 12px 0;
		width: $card-highlight--image-desktop-width;
		height: $card-highlight--image-desktop-height;
		aspect-ratio: $card-highlight--image-aspect-ratio;
	}

	.app-card-highlight .app-card-highlight__text img {
		max-width: $card-highlight--image-in-content-max-width;
	}

	.app-card-highlight__side {
		margin-bottom: 0;
	}
}

@include from-desktop {
	.app-card-highlight--stack {
		flex-direction: column-reverse;
		border-left: none;
		border-top: $card-highlight--highlight-border-width-style $card-highlight--border-color;
	}

	.app-card-highlight--stack .app-card-highlight__side img {
		border-radius: initial;
	}

	.app-card-highlight__content {
		display: block;
		flex-direction: unset;
		justify-content: unset;
	}

	.app-card-highlight__heading {
		margin-bottom: $card-highlight--heading-margin-bottom;
	}

	.app-card-highlight--stack .app-card-highlight__side {
		max-width: none;
		margin: -16px -16px 0 -16px;
	}

	.app-card-highlight--stack .app-card-highlight__heading {
		margin-top: $t-a-space-absolute-400;
	}
}
