.app-coremedia-content-page {
	overflow-wrap: initial; // to reset the overflow-wrap set on app-page-layout

	.underline {
		text-decoration: underline;
		-webkit-text-decoration-skip: skip;
		text-decoration-skip-ink: auto;
	}

	.strike {
		text-decoration: line-through;
	}

	.float--none {
		float: none;
	}

	.float--left {
		float: left;
		margin-right: $t-a-space-absolute-400;
		margin-bottom: $t-a-space-absolute-400;
	}

	.float--right {
		float: right;
		margin-left: $t-a-space-absolute-400;
		margin-bottom: $t-a-space-absolute-400;
	}

	.align--left {
		text-align: left;
	}

	.align--center {
		text-align: center;
	}

	.align--right {
		text-align: right;
	}

	.section-underline {
		@include eds-c-section-heading;
	}

	.divider--paragraph {
		border-top: 2px solid $t-a-palette-default-greyscale-400;
		padding: $t-a-space-absolute-400 0 0;
	}

	img {
		border-radius: 10px;
	}

	p img.float--right,
	p img.float--left,
	p img.float--none,
	p img {
		height: auto;
	}

	@include from-tablet {
		p img.float--right,
		p img.float--left {
			width: auto;
			max-width: 50%;
		}

		p:has(> img)::after {
			content: ".";
			visibility: hidden;
			display: block;
			height: 0;
			clear: left;
		}
	}

	//TODO - This blockquote styling needs to be removed once EDS team release the blockquote styling. Discussion started here: https://github.com/springernature/eds/discussions/342
	blockquote {
		font-family: $t-a-typography-font-family-default;
		font-weight: $t-a-typography-font-weight-bold;
		line-height: $t-a-typography-line-height-md;
		background-color: $t-a-palette-default-blue-lighter;
		background-image: url('/oscar-static/images/icon-quote.svg');
		background-position: 16px 18px;
		background-repeat: no-repeat;
		background-size: 24px;
		margin: 0 0 $t-a-space-absolute-800;
		padding: $t-a-space-absolute-400 $t-a-space-absolute-400 $t-a-space-absolute-400 $t-a-space-absolute-1200;
	}

	blockquote.float--left,
	blockquote.float--right {
		margin: 0 0 $t-a-space-absolute-800;
		float: none;
	}


	// To style back link in submission guidelines cms
	.back-link {
		font-family: $t-a-typography-font-family-default;
		display: block;

		svg {
			width: 16px;
			height: 16px;
		}
	}

	@include from-desktop {
		blockquote.float--left,
		blockquote.float--right {
			width: 30%;
		}

		blockquote.float--right {
			float: right;
			margin: 0 0 $t-a-space-absolute-400 $t-a-space-absolute-400;
		}

		blockquote.float--left {
			float: left;
			margin: 0 $t-a-space-absolute-400 $t-a-space-absolute-400 0;
		}
	}
}
