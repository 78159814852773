$listing-link-color: $t-a-palette-default-greyscale-800;
$listing-spacing: spacing(16);

.app-societies.app-societies--sidebar {
	flex-grow: 0;
	flex-basis: auto;
	margin: 0 auto spacing(32);
}

@include from-tablet {
	.app-societies.app-societies--sidebar {
		margin: 0 0 spacing(32);
	}
}

.app-societies__heading {
	@include u-h4;
}

.app-societies__description {
	font-size: $t-a-typography-font-size-xs;
}

.app-societies__list {
	@include u-list-reset;
	display: grid;
	grid-template-columns: 1fr;
	gap: $listing-spacing 0;

	margin-top: spacing(24);

	@include media-query('sm') {
		grid-template-columns: repeat(2, 1fr);
		gap: $listing-spacing $listing-spacing;
	}
}

.app-societies__list-item {
	display: grid;
	align-items: stretch;
}

.app-societies__list-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: $listing-spacing;
	color: $listing-link-color;

	padding: spacing(24);
	border: 1px solid $t-l-color-blue-300;
	border-radius: 8px;
	font-size: $t-a-typography-font-size-xs;
	line-height: $t-a-typography-line-height-md;

	@include media-query('sm') {
		flex-basis: calc(50% - $listing-spacing / 2);
		flex-direction: row;
	}

	&:hover,
	&:visited {
		color: $listing-link-color;
	}
}

.app-societies__list-image {
	flex: 0 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 125px;

	img {
		max-width: 125px;
		max-height: 72px;
		width: auto;
		height: auto;
	}
}

.app-societies__list-text {
	text-align: center;

	@include media-query('sm') {
		text-align: left;
	}
}

.app-societies__list-link-icon {
	width: 16px;
	height: 16px;
	margin-left: spacing(4);
	vertical-align: middle;
	fill: $listing-link-color;
}

.app-societies__single-image {
	margin: 0 auto;
	max-width: 125px;

	img {
		max-width: 125px;
		max-height: 72px;
		width: auto;
		height: auto;
	}

	@include from-tablet {
		margin: 0;
	}
}

