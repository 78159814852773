.app-journal-masthead {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	grid-template-rows: auto;
	padding: $t-a-space-absolute-400 0 $t-a-space-absolute-600;
	font-family: "Merriweather Sans","Helvetica Neue","Helvetica","Arial",sans-serif;
	/* stylelint-disable declaration-colon-space-after */
	/* stylelint-disable indentation */
	grid-template-areas:
		"breadcrumbs breadcrumbs"
		"journal-cover imprints-and-societies"
		"info info";
	/* stylelint-enable declaration-colon-space-after */
	/* stylelint-enable indentation */

	&--imprint-with-society {
		/* stylelint-disable declaration-colon-space-after */
		/* stylelint-disable indentation */
		grid-template-areas:
		"breadcrumbs breadcrumbs"
		"journal-cover journal-cover"
		"info info"
		"imprints-and-societies imprints-and-societies";
		/* stylelint-enable declaration-colon-space-after */
		/* stylelint-enable indentation */
	}

	&--journal-child-pages {
		grid-template-columns: auto 1fr;
		/* stylelint-disable declaration-colon-space-after */
		/* stylelint-disable indentation */
		grid-template-areas:
		"breadcrumbs breadcrumbs"
		"journal-cover info";
		/* stylelint-enable declaration-colon-space-after */
		/* stylelint-enable indentation */
	}
}

.app-journal-masthead__breadcrumbs {
	grid-area: breadcrumbs;
}

.app-journal-masthead__imprints-and-societies {
	grid-area: imprints-and-societies;
	justify-self: flex-end;
	padding-top: $t-a-space-absolute-400;

	.app-journal-masthead--imprint-with-society & {
		justify-self: initial;
		padding-top: 0;
	}

	.app-journal-masthead--journal-child-pages & {
		display: none;
	}
}

.app-journal-masthead__info {
	grid-area: info;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-top: $t-a-space-absolute-200;
	font-size: $t-a-typography-font-size-s;

	.app-journal-masthead--journal-child-pages & {
		padding: $t-a-space-absolute-400 0 0 $t-a-space-absolute-200;
		line-height: $t-a-typography-line-height-tighter;
	}
}

.app-journal-masthead__title {
	margin: 0;
	line-height: $t-a-typography-line-height-tighter;
	font-weight: $t-a-typography-font-weight-bold;
	word-break: break-word;

	.app-journal-masthead--journal-child-pages & {
		font-size: $t-a-typography-font-size-md;
	}
}

.app-journal-masthead__subtitle {
	line-height: $t-a-typography-line-height-tighter;

	.app-journal-masthead--journal-child-pages & {
		display: none;
	}
}

.app-journal-masthead__publishing-model {
	font-size: $t-a-typography-font-size-xs;
	padding: $t-a-space-absolute-400 0;

	& dl {
		margin: 0;
	}

	& dd {
		font-weight: $t-a-typography-font-weight-bold;
	}

	.app-journal-masthead--journal-child-pages & {
		padding: $t-a-space-absolute-200 0 0;

		& dl {
			display: flex;
		}
	}
}

.app-journal-masthead__publishing-model-colon {
	display: none;
}

.app-journal-masthead__publishing-model-colon {
	.app-journal-masthead--journal-child-pages & {
		display: inline-block;
		margin-right: $t-a-space-absolute-100;
	}
}

.app-journal-masthead__title-link,
.app-journal-masthead__publishing-model-link {
	&,
	&:hover,
	&:focus,
	&:active,
	&:visited {
		color: currentColor;
	}
}

.app-journal-masthead__journal-cover {
	grid-area: journal-cover;
	padding-top: $t-a-space-absolute-400;
}

.app-journal-masthead__journal-cover {
	& img {
		border: 2px solid $t-a-palette-default-white;
		border-radius: 7px;
		width: 100%;
		height: auto;
		max-width: 90px;
	}

	.app-journal-masthead--journal-child-pages & {
		& img {
			max-width: 64px;
		}
	}
}

.app-journal-masthead__button .eds-c-button {
	background-color: $t-a-palette-default-white;
	border: 2px solid $t-a-palette-default-white;
	color: $t-a-palette-default-blue-dark-900;
	width: 100%;

	.app-journal-masthead--journal-child-pages & {
		display: none;
	}

	svg {
		fill: $t-a-palette-default-blue-dark-900;
	}
}

.app-journal-masthead__imprint-society-container {
	border: 2px solid $t-a-palette-default-white;
	border-radius: 7px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: $t-a-space-absolute-800;

	& .app-journal-masthead__imprint,
	& .app-journal-masthead__society {
		flex-basis: 60px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: $t-a-space-absolute-400;
	}

	& .app-journal-masthead__society span:nth-child(2) {
		margin-left: 48px;
	}

	& .app-journal-masthead__imprint img,
	& .app-journal-masthead__society img {
		width: auto;
		height: auto;
	}

	& .app-journal-masthead__imprint img {
		max-width: 168px;
		max-height: 24px;
	}


	& .app-journal-masthead__society img {
		max-width: 80px;
		max-height: 36px;
	}

	& .app-journal-masthead__society {
		background-color: $t-a-palette-default-white;
	}
}

@include from-tablet {
	.app-journal-masthead {
		grid-template-columns: auto minmax(0, 1fr) auto;
		/* stylelint-disable declaration-colon-space-after */
		/* stylelint-disable indentation */
		grid-template-areas:
		"breadcrumbs breadcrumbs imprints-and-societies"
		"journal-cover info imprints-and-societies";
		/* stylelint-enable declaration-colon-space-after */
		/* stylelint-enable indentation */
		padding: $t-a-space-absolute-600 0 $t-a-space-absolute-800;

		&--imprint-with-society {
			grid-template-columns: auto minmax(0, 1fr);
			/* stylelint-disable declaration-colon-space-after */
			/* stylelint-disable indentation */
			grid-template-areas:
			"breadcrumbs breadcrumbs"
			"journal-cover info"
			"imprints-and-societies imprints-and-societies";
			/* stylelint-enable declaration-colon-space-after */
			/* stylelint-enable indentation */
		}
	}

	.app-journal-masthead__journal-cover {
		&,
		.app-journal-masthead--journal-child-pages & {
			& img {
				max-width: 160px;
			}
		}

	}

	.app-journal-masthead__imprints-and-societies {
		padding-top: 0;

		.app-journal-masthead--journal-child-pages & {
			display: inline-block;
		}
	}

	.app-journal-masthead__info {
		&,
		.app-journal-masthead--journal-child-pages & {
			padding-left: $t-a-space-absolute-600;
			padding-top: $t-a-space-absolute-600;
			line-height: $t-a-typography-line-height-base;
		}
	}

	.app-journal-masthead__button {
		align-self: flex-start;
	}

	.app-journal-masthead__title {
		&,
		.app-journal-masthead--journal-child-pages & {
			line-height: $t-a-typography-line-height-tighter;
			font-size: $t-a-typography-heading-level-1-default-font-size;
		}
	}

	.app-journal-masthead__subtitle {
		font-size: $t-a-typography-font-size-md;
		margin-right: -64px;

		.app-journal-masthead--journal-child-pages & {
			display: block;
		}
	}

	.app-journal-masthead__publishing-model {
		&,
		.app-journal-masthead--journal-child-pages & {
			padding: $t-a-space-absolute-600 0 $t-a-space-absolute-400;
		}

		& dd {
			font-size: $t-a-typography-font-size-s;
		}

		.app-journal-masthead--journal-child-pages & dl {
			display: block;
		}
	}

	.app-journal-masthead__publishing-model-colon {
		&,
		.app-journal-masthead--journal-child-pages & {
			display: none;
		}
	}

	.app-journal-masthead__journal-cover {
		padding-top: $t-a-space-absolute-600;
	}

	.app-journal-masthead__button .eds-c-button {
		margin-right: -64px;
		width: auto;

		.app-journal-masthead--journal-child-pages & {
			display: inline-block;
		}
	}
}

@include from-desktop {
	.app-journal-masthead {
		&--imprint-with-society {
			grid-template-columns: auto minmax(0, 1fr) minmax(0, 300px);
			/* stylelint-disable declaration-colon-space-after */
			/* stylelint-disable indentation */
			grid-template-areas:
			"breadcrumbs breadcrumbs breadcrumbs"
			"journal-cover info imprints-and-societies";
			/* stylelint-enable declaration-colon-space-after */
			/* stylelint-enable indentation */
		}
	}
	.app-journal-masthead__subtitle {
		margin-right: 0;
	}

	.app-journal-masthead__button .eds-c-button {
		margin-right: 0;
	}

	.app-journal-masthead__imprints-and-societies {
		.app-journal-masthead--imprint-with-society & {
			padding-top: $t-a-space-absolute-600;
		}
	}

	.app-journal-masthead__imprint-society-container {
		margin-top: 0;

		& .app-journal-masthead__imprint,
		& .app-journal-masthead__society {
			flex-basis: 100px;
		}

		& .app-journal-masthead__society span:nth-child(2) {
			margin-left: 32px;
		}

		& .app-journal-masthead__imprint img {
			max-width: 125px;
			max-height: 32px;
		}

		& .app-journal-masthead__society img {
			max-height: 50px;
			max-width: 105px;
		}
	}
}

.app-masthead-funding-info {
	display: flex;
	flex-direction: row;
	gap: spacing(8);
	font-size: $t-a-typography-font-size-xs;
	line-height: $t-a-typography-line-height-md;
	padding: spacing(16) 0;


	& a {
		color: $t-a-palette-default-white;
	}
}

@include from-tablet {
	.app-masthead-funding-info {
		padding: spacing(24) 0;
		gap: spacing(0);
	}
}

.app-masthead-funding-info__details {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	gap: spacing(8);
}

@include from-tablet {
	.app-masthead-funding-info__details {
		flex-direction: row;
		gap: 0;
	}
}

.app-masthead-funding-info__institution-details {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
}

.app-masthead-funding-info__links {
	font-weight: $t-a-typography-font-weight-bold;
}

.app-masthead-funding-info__links:not(:last-child) {
	border-right: 1px solid #fff;
	padding-right: spacing(8);
	margin-right: spacing(8);

	&,
	&:hover,
	&:focus,
	&:active,
	&:visited {
		color: currentColor;
	}
}

.app-masthead-funding-info__icon {
	fill: currentColor;
	width: 24px;
	height: 24px;
	flex-shrink: 0;
	margin-right: spacing(8);
}

.app-masthead-funding-info__institution {
	font-weight: $t-a-typography-font-weight-bold;
	padding-right: spacing(16);
	padding-bottom: spacing(8);
	display: flex;
}

@include from-tablet {
	.app-masthead-funding-info__institution {
		padding-bottom: spacing(0);
	}
}

.app-masthead-funding-info__message {
	margin-right: spacing(16);
}

.app-masthead-funding__wrapper {
	background: rgba(255, 255, 255, .05);
	position: relative;
	z-index: 2;
}
