$app-institution-signpost--background-color: #eff6fb;
$app-institution-signpost--border-radius: 12px;
$app-institution-signpost--icon-size: 24px;
$app-institution-signpost--icon-container-size: 48px;
$app-institution-signpost--icon-size-desktop: 32px;
$app-institution-signpost--icon-container-size-desktop: 64px;

.app-institution-signpost {
	background-color: $app-institution-signpost--background-color;
	font-size: $t-a-typography-font-size-s;
	font-family: $t-a-typography-font-family-default;
	line-height: $t-a-typography-line-height-md;
	margin: 0 -16px;
	min-height: 114px;
	position: relative;
	padding: spacing(24) spacing(16);
}

.app-content-page .app-institution-signpost__name {
	font-size: 1.375rem;
	margin: 0 0 spacing(8);
}

.app-institution-signpost__adjust-for-icon {
	width: calc(100% - $app-institution-signpost--icon-container-size);
}

.app-institution-signpost__icon-container {
	align-items: center;
	background-color: $t-a-palette-default-white;
	border-radius: 50%;
	display: flex;
	flex-shrink: 0;
	justify-content: center;
	position: absolute;
	right: 16px;
	top: 16px;
	height: $app-institution-signpost--icon-container-size;
	width: $app-institution-signpost--icon-container-size;
}

.app-institution-signpost__icon {
	display: inline-block;
	fill: $t-a-palette-default-blue-light-400;
	height: $app-institution-signpost--icon-size;
	width: $app-institution-signpost--icon-size;
}

.app-institution-signpost__separator {
	border-top: 1px solid $t-a-palette-default-blue-light;
	margin-top: spacing(24);
	padding-top: spacing(24);
}

.app-institution-signpost__funding-msg {
	font-size: $t-a-typography-font-size-default;
	font-weight: $t-a-typography-font-weight-bold;
	margin: 0 0 spacing(8);

	p {
		margin: 0 0 spacing(8);
	}
}

.app-content-page a.app-institution-signpost__link {
	color: $t-a-palette-default-greyscale-700;
	font-size: $t-a-typography-font-size-xs;
	margin: 0;

	&:visited,
	&:hover {
		color: $t-a-palette-default-greyscale-700;
	}
}

.app-content-page a.app-institution-signpost__funding-link {
	color: $t-a-palette-default-greyscale-800;
	font-weight: $t-a-typography-font-weight-normal;
	margin: 0;

	&:visited,
	&:hover {
		color: $t-a-palette-default-greyscale-800;
	}
}

.app-institution-signpost__inline-icon {
	fill: currentColor;
	display: inline-block;
	margin-left: 4px;
	vertical-align: middle;
}

.app-institution-signpost__inline-icon--edit {
	margin-left: 0;
	margin-right: 4px;
}

@include from-tablet {
	.app-institution-signpost {
		border-radius: $app-institution-signpost--border-radius;
		margin: 0;
		padding: spacing(24);
	}

	.app-institution-signpost__icon-container {
		right: 24px;
		top: 24px;
		height: $app-institution-signpost--icon-container-size-desktop;
		width: $app-institution-signpost--icon-container-size-desktop;
	}

	.app-institution-signpost__icon {
		height: $app-institution-signpost--icon-size-desktop;
		width: $app-institution-signpost--icon-size-desktop;
	}

	.app-institution-signpost__adjust-for-icon {
		width: calc(100% - $app-institution-signpost--icon-container-size-desktop);
	}
}
