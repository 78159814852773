.app-show-more-show-less {
	display: block;
	@include link-like;
}

.app-show-more-show-less__icon {
	fill: currentColor;
	transform: translate(0, 0);
	display: inline-block;
	width: clamp(1rem, 4vw, 1.5rem);
	height: clamp(1rem, 4vw, 1.5rem);
	vertical-align: middle;
	transition-duration: 0.2s;
}

.app-show-more-show-less--expanded .app-show-more-show-less__icon {
	transform: rotate(180deg);
}

[data-show-more-show-less-trigger] span {
	font-size: 0.875rem;
}

// written this to give a little boost to the css specificity score to ensure overrides these values where set elsewhere
[data-show-more-show-less-target].show-more-show-less.app-show-more-show-less--expanded {
	display: block;
	-webkit-line-clamp: unset;
	line-clamp: unset;
	-webkit-box-orient: unset;
	overflow: initial;
}

@include from-tablet {
	[data-show-more-show-less-trigger-text] {
		font-size: inherit;
	}
}
