.app-accordion {
	width: 100%;
}

.app-accordion__button-container {
	display: flex;
	flex-direction: row-reverse; // needed for .app-accordion__strip adjacent .app-accordion__button.is-open css rule
	justify-content: space-between;
}

.app-accordion__button {
	@include u-button-reset;
	width: 100%;
	flex-grow: 1;
	padding: $app-accordion--button-padding;
	border-top: 1px solid $app-accordion--primary-color;
	border-bottom: 1px solid $app-accordion--primary-color;
}

@include from-tablet {
	.app-accordion__button {
		border-left: 1px solid $app-accordion--primary-color;
		border-right: 1px solid $app-accordion--primary-color;
	}
}

.app-accordion + .app-accordion .app-accordion__button,
li + li > .app-accordion .app-accordion__button {
	border-top: none;
}

.app-accordion__button.is-open {
	border-left: none;
	padding-left: 1rem;
}

.app-accordion__button.is-open + .app-accordion__strip {
	flex-grow: 0;
	width: $app-accordion--strip-width;
	background-color: $app-accordion--secondary-color;
}

.app-accordion__icon {
	width: 1em;
	height: 1em;
	color: $app-accordion--quaternary-color;
	fill: $app-accordion--quaternary-color;
}

.app-accordion__button.is-open .app-accordion__icon {
	color: $app-accordion--secondary-color;
	fill: $app-accordion--secondary-color;
	transform: rotate(180deg);
}

.app-accordion__header {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: $app-accordion--header-gap;
}

.app-accordion__title {
	display: inline-block;
	color: $app-accordion--quaternary-color;
	font-family: $t-a-typography-font-family-default;
	font-size: $app-accordion--font-size;
	font-weight: $t-a-typography-font-weight-bold;
	line-height: $t-a-typography-line-height-md;
}

.app-accordion__button.is-open .app-accordion__title {
	color: $app-accordion--secondary-color;
}

.app-accordion__content {
	background-color: $app-accordion--background-color;
	padding: $app-accordion--content-padding;
	border-bottom: 1px solid $app-accordion--primary-color;
}

.app-accordion__content ul {
	@include u-list-reset;
}

.app-accordion__content {
	font-size: $app-accordion--font-size;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-size: $app-accordion--heading-font-size;
	}

	h1:first-child,
	h2:first-child,
	h3:first-child,
	h4:first-child,
	h5:first-child,
	h6:first-child {
		margin-top: 0;
	}

	li + li {
		margin-top: $app-accordion--list-item-spacing;
	}

	a {
		color: $app-accordion--tertiary-color;
		line-height: $t-a-typography-line-height-md;
	}

	a:active {
		font-weight: 700;
		outline: $t-l-spacing-absolute-100 solid $t-a-palette-interaction-focus;
	}
}

.app-accordion__content.app-accordion__content--grouped > ul > li + li {
	margin-top: $app-accordion--content-grouped-spacing;
}
