.app-latest-articles-ctas {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	margin-top: spacing(24);
	gap: spacing(16);
}

.app-latest-articles-ctas__button {
	flex-basis: 100%;
}

@include from-tablet {
	.app-latest-articles-ctas {
		justify-content: initial;
	}

	.app-latest-articles-ctas__button {
		flex-basis: auto;
	}
}

.app-latest-articles-ctas__message {
	font-size: $t-a-typography-font-size-s;
}

.app-latest-articles-ctas__link {
	display: inline-flex;
	align-items: center;
}

.app-latest-articles-ctas__icon {
	width: 0.875rem;
	height: 0.875rem;
	margin-left: spacing(4);
	fill: currentColor;
}
