/**
 * Status Message
 * Default branding for component
 */

$status-message-theme: (
	"info": $t-a-palette-information-blue,
	"error": $t-a-palette-information-red,
	"success": $t-a-palette-information-green,
	"warning": $t-a-palette-information-orange
);

$status-message--font-family: $t-a-typography-font-family-default;
$status-message--font-size: $t-a-typography-font-size-xs;
$status-message--border: 1px solid $t-a-palette-default-greyscale-400;
$status-message--border-radius: 8px;
$status-message--boxed-banner: 4px;
$status-message--background-color: white;
$status-message--line-height: $t-a-typography-line-height-tight;
$status-message--gutter: spacing(16);
$status-message--gutter-icon: $t-a-space-absolute-200;
$status-message--font-weight: $t-a-typography-font-weight-bold;
$status-message--icon-size: 21px;
