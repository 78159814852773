.app-navigation__desktop-menu,
.app-navigation__dropdown {
	display: none;
}

.app-navigation__mobile-menu {
	@include u-list-reset;
}

.app-navigation__expander-enhanced-but-no-js {
	.app-navigation__menu,
	.app-navigation__list {
		@include u-list-reset;
	}

	.app-navigation__menu {
		@include u-container;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
		grid-column-gap: $t-a-space-absolute-400;
		grid-row-gap: $t-a-space-absolute-800;
	}

	.app-navigation__dropdown {
		display: block;
	}

	.app-navigation__link-icon {
		display: none;
	}
}

.app-navigation__no-dropdown a {
	display: block;
	width: 100%;
	text-decoration: none;
	color: $t-a-palette-default-greyscale-700;
	font-family: $t-a-typography-font-family-default;
	font-size: $t-a-typography-font-size-xs;
	font-weight: $t-a-typography-font-weight-bold;
	line-height: $t-a-typography-line-height-md;
	padding: $t-a-space-relative-400 $t-a-space-relative-600;
	border-top: 1px solid $t-a-palette-default-blue-light;
	border-bottom: 1px solid $t-a-palette-default-blue-light;
}

li + .app-navigation__no-dropdown a {
	border-top: none;
}

@include from-wide-tablet {
	.app-navigation {
		border-bottom: $app-navigation--bottom-border; // decided that eds-c-navigation should own the border so consumers get a nice default border eds should cancel out this border in eds-c-header if needed
	}

	.app-navigation__dropdown {
		display: block;
	}

	.app-navigation__mobile-menu {
		display: none;
	}

	.app-navigation__desktop-menu {
		@include u-list-reset;
		align-items: center;
		background-color: $app-navigation--background-color;
		display: flex;
		flex-direction: row;
		font-family: $app-navigation--font-family;
		font-size: $app-navigation--font-size;
		font-weight: $app-navigation--font-weight;
		gap: $app-navigation--menu-items-gap;
		justify-content: flex-start;
		line-height: $t-l-line-height-tighter;
		padding: $app-navigation--padding;
	}

	.app-navigation__menu-link {
		position: relative;
		display: flex;
		text-decoration: none;
		color: $app-navigation--menu-item-color;
		align-items: center;
		padding: $app-navigation--menu-items-padding;

		&:before {
			content: "";
			position: absolute;
			display: block;
			width: 100%;
			height: $app-navigation--pill-height;
			bottom: $app-navigation--pill-bottom-position;
			left: 0;
			background-color: transparent;
			border-radius: $app-navigation--pill-border-radius;
		}

		&:hover,
		&.is-open {
			color: $app-navigation--active-color;

			&:before {
				background-color: $app-navigation--pill-bg-color;
			}

			.app-navigation__icon {
				color: $app-navigation--icon-color-open;
				fill: $app-navigation--icon-color-open;
			}
		}

		&.is-open {
			.app-navigation__icon {
				transform: $app-navigation--icon-transform-rotate;
			}
		}

		&--active {
			color: initial;

			&:before {
				background-color: $t-a-palette-default-blue-medium-700;
			}
		}

	}

	.app-navigation__icon {
		width: $app-navigation--icon-size;
		height: $app-navigation--icon-size;
		margin-left: $app-navigation--icon-margin-left-200;
		transition: $app-navigation--icon-transition;
		color: $app-navigation--icon-color-default;
		fill: $app-navigation--icon-color-default;
	}

	.app-navigation--tray-dropdown {
		.app-navigation__heading {
			font-size: $t-a-typography-font-size-s;
			line-height: $t-l-line-height-base;
			margin: 0 0 8px;
		}

		.app-navigation__list {
			@include u-list-reset;
			font-size: $app-navigation--font-size;
			color: $t-a-palette-default-greyscale-800;
			font-weight: $t-a-typography-font-weight-normal;
			line-height: $t-l-line-height-base;
		}

		.app-navigation__list-link {
			color: currentColor;
		}

		.app-navigation__dropdown {
			display: flex;
			row-gap: $app-navigation--dropdown-gap;
			width: 100%;
			background-color: $app-navigation--background-color;
			border-bottom: $app-navigation--dropdown-border;
			padding: $app-navigation--dropdown-padding;
			box-shadow: $app-navigation--dropdown-shadow;
		}

		.app-navigation__dropdown.has-tethered {
			position: absolute;
			z-index: 10;
			top: 100%;
			left: 0;
			flex-direction: row;
			column-gap: $app-navigation--dropdown-gap;
			border-radius: $app-navigation--dropdown-border-radius;
			border: $app-navigation--dropdown-border-open;
		}

		.app-navigation__dropdown-group {
			padding: $app-navigation--dropdown-group-padding;
		}
	}

	.app-navigation--fixed-width-dropdown {
		.app-navigation__desktop-menu {
			border-bottom: none;
		}

		.app-navigation__list {
			@include u-list-reset;
			display: flex;
			flex-direction: column;
			font-size: $app-navigation--list-font-size;
			color: $app-navigation--list-color;
			font-weight: $app-navigation--list-font-weight;
			line-height: $app-navigation--list-line-height;
		}

		.app-navigation__dropdown {
			padding: 9px 0;
		}

		.app-navigation__menu-item {
			position: relative;
		}

		.app-navigation__dropdown.has-tethered {
			position: absolute;
			width: 264px;
			z-index: 10;
			top: calc(100% + 8px);
			left: 0;
			flex-direction: row;
			background-color: $app-navigation--background-color;
			border-radius: $t-a-border-radius-200;
			border: 1px solid $t-l-color-blue-300;
			box-shadow: 0 4px 12px 0 rgba(1, 50, 75, 0.1);
		}

		.app-navigation__list-link {
			display: flex;
			align-items: center;
			width: 100%;
			height: 48px;
			color: currentColor;
			text-decoration: none;
			background-color: transparent;
			padding: 0 24px;
			transition: all 0.2s;
		}

		.app-navigation__list-link--active {
			background-color: $app-navigation--list-link-active-background-color;
			font-weight: $t-a-typography-font-weight-bold;

			.app-navigation__link-icon {
				color: $app-navigation--list-link-active-icon-color;
				fill: $app-navigation--list-link-active-icon-color;
			}
		}

		.app-navigation__list-link:focus {
			background-color: $app-navigation--list-link-background-color;
			color: $app-navigation--list-link-text-color;
			outline: none;

			.app-navigation__link-icon {
				color: currentColor;
				fill: currentColor;
			}
		}

		.app-navigation__list-link:hover {
			background-color: $app-navigation--list-link-background-color;
			color: $app-navigation--list-link-text-color;

			.app-navigation__link-icon {
				color: currentColor;
				fill: currentColor;
			}
		}

		.app-navigation__list:hover .app-navigation__list-link:focus:not(:hover) {
			background-color: transparent;
			color: currentColor;

			.app-navigation__link-icon {
				color: $app-navigation--icon-color-default;
				fill: $app-navigation--icon-color-default;
			}
		}

		.app-navigation__list:hover .app-navigation__list-link--active:focus:not(:hover) {
			background-color: $app-navigation--list-link-active-background-color;
			font-weight: $t-a-typography-font-weight-bold;

			.app-navigation__link-icon {
				color: $app-navigation--list-link-active-icon-color;
				fill: $app-navigation--list-link-active-icon-color;
			}
		}

		.app-navigation__link-icon {
			width: $app-navigation--icon-size;
			height: $app-navigation--icon-size;
			margin-left: $app-navigation--icon-margin-left-100;
			transition: $app-navigation--icon-transition;
			color: $app-navigation--icon-color-default;
			fill: $app-navigation--icon-color-default;
		}
	}

}
