$app-accordion--button-padding: $t-a-space-relative-400 $t-a-space-relative-600 $t-a-space-relative-400 $t-a-space-relative-600;
$app-accordion--content-padding: $t-a-space-relative-600;
$app-accordion--header-gap: $t-a-space-relative-200;
$app-accordion--primary-color: $t-a-palette-default-blue-light;
$app-accordion--secondary-color: $t-a-palette-default-blue-medium-700;
$app-accordion--tertiary-color: $t-a-palette-default-greyscale-800;
$app-accordion--quaternary-color: $t-a-palette-default-greyscale-700;
$app-accordion--background-color: $t-l-color-blue-200;
$app-accordion--list-item-spacing: $t-a-space-relative-400;
$app-accordion--font-size: $t-a-typography-font-size-xs;
$app-accordion--heading-font-size: $t-a-typography-font-size-s;
$app-accordion--heading-margin: 0 0 $t-a-space-relative-400;
$app-accordion--strip-width: 8px;
$app-accordion--content-grouped-spacing: $t-a-space-relative-800;
