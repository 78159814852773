.app-ethics-and-disclosures-images {
	display: flex;
}

.app-ethics-and-disclosures-images img {
	border: 1px solid $t-a-palette-default-blue-light;
	border-radius: 10px;
	padding: spacing(24);
	object-fit: contain;
	height: 100px;
	width: auto;
}

@include from-tablet {
	.app-ethics-and-disclosures-images {
		display: block;
	}

	.app-ethics-and-disclosures-images img {
		width: 180px;
		height: 134px;
	}
}
