$app-info-presentation-box--border-color: $t-a-palette-default-blue-light;
$app-info-presentation-box--gutter-md: spacing(32);
$app-info-presentation-box--gutter-sm: spacing(16);
$app-info-presentation-box--gutter: spacing(24);
$app-info-presentation-box--icon-background: #f0f7fc;
$app-info-presentation-box--icon-color: $t-a-palette-default-blue-light-400;
$app-info-presentation-box--item-padding: spacing(16) spacing(16) spacing(24);
$app-info-presentation-box--text-font-size: $t-a-typography-font-size-xs;
$app-info-presentation-box__title-font-size: $t-a-typography-font-size-default;

.app-info-presentation-box {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 0 0 $app-info-presentation-box--gutter-sm;
	line-height: $t-a-typography-line-height-tight;
}

.app-info-presentation-box__item {
	width: 100%;
	border: 1px solid $app-info-presentation-box--border-color;
	border-radius: 10px;

	& + .app-info-presentation-box__item {
		margin-top: $app-info-presentation-box--gutter-sm;
	}
}

.app-info-presentation-box__item-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding: $app-info-presentation-box--item-padding;
}

.app-info-presentation-box__icon-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 64px;
	height: 64px;
	border-radius: 50%;
	background-color: $app-info-presentation-box--icon-background;
	padding: 14px;
}

.app-info-presentation-box__icon {
	fill: $app-info-presentation-box--icon-color;
	width: 2rem;
	height: 2rem;
}

.app-info-presentation-box__title {
	// TODO - this can removed after https://github.com/springernature/eds/pull/270 and the subsequent release
	font-size: $app-info-presentation-box__title-font-size;
	margin: 10px 0 0;
}

.app-info-presentation-box__item p {
	font-family: $t-a-typography-font-family-default;
	font-size: $app-info-presentation-box--text-font-size;
	margin: 4px 0 0;
}

@include from-wide-tablet {

	.app-info-presentation-box {
		flex-direction: row;
		margin: 0 0 $app-info-presentation-box--gutter-md;
	}

	.app-info-presentation-box__item {
		width: calc(34% - $app-info-presentation-box--gutter-sm);

		& + .app-info-presentation-box__item {
			margin-top: 0;
		}
	}
}



