.app-journal-homepage-metrics {
	display: flex;
	align-items: center;
	border-bottom: 1px solid $t-a-palette-default-blue-light;

	&:first-of-type {
		border-top: 1px solid $t-a-palette-default-blue-light;;
	}

	& svg {
		flex-shrink: 0;
		display: inline-block;
		width: 32px;
		height: 32px;
		margin-right: $t-a-space-absolute-400;
	}
}
