.app-content-page {
	font-family: $t-a-typography-font-family-long-text;
	overflow-wrap: unset; // to reset the overflow-wrap set on app-page-layout, initial brakes in mobile

	a {
		overflow-wrap: anywhere;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: $t-a-typography-heading-font-family;
	}

	// TODO - this can removed after https://github.com/springernature/eds/discussions/199#discussioncomment-6450452 is resolved and any related release is consumed
	h1 {
		@include u-h2;
		margin: 0 0 clamp(1rem, 2vw, 3rem);
	}

	h2 {
		@include u-h3;
		margin: clamp(0.5rem, 0.5vw, 0.75rem) 0 clamp(1rem, 2vw, 2rem);
	}

	h3 {
		font-size: 1.375rem;
		margin: clamp(0.5rem, 0.5vw, 0.75rem) 0 clamp(1rem, 1.5vw, 1.5rem);
	}

	h4 {
		@include u-h4;
		margin: clamp(0.5rem, 0.5vw, 0.75rem) 0 clamp(1rem, 1.5vw, 1.5rem);
	}

	h5 {
		@include u-h5;
		margin: clamp(0.5rem, 0.5vw, 0.75rem) 0 clamp(1rem, 1.5vw, 1.5rem);
	}

	h6 {
		font-size: 1rem;
		font-weight: 700;
		margin: clamp(0.25rem, 1vw, 1rem) 0;
	}

	// Temporary fix to remove last margin bottom of 'back to top' button in Submission Guidelines
	.backToTop {
		margin-bottom: spacing(48);
	}

	.u-mt-24.u-mb-48:last-of-type {
		margin-bottom: 0;
	}
}
