/**
* @springernature/eds/forms
* Default skin settings
*
*/

$forms-checkbox-border-color: #999;
$forms-checkbox-border-size: 2px;
$forms-checkbox-checked-icon-data-uri: 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" stroke-width="3" stroke="white" stroke-linejoin="round" fill="none" stroke-linecap="round" viewBox="0 0 20 20"><path d="M4.5,11 10,15 16,5"></path></svg>';
$forms-checkbox-checked-icon-fill: #01324b;
$forms-checkbox-checked-icon-stroke: #01324b;
$forms-checkbox-size: 1.5rem;
$forms-checkbox-size-small: 1rem;
$forms-checkbox-spacing: .5rem;
$forms-field-stack-spacing: 1.5rem;
$forms-field-stack-spacing-small: 1rem;
$forms-hint-color: #666;
$forms-hint-font-weight: 400;
$forms-indent-border-color: #dadada;
$forms-indent-border-radius: 4px;
$forms-input-background-color: #fff;
$forms-input-border-color-disabled: #dadada;
$forms-input-border-color-error: #c40606;
$forms-input-border-color-resting: #222;
$forms-input-border-width: 1px;
$forms-input-focus-outline-color: #08c;
$forms-input-font-color: #000;
$forms-input-font-size: 1rem;
$forms-input-font-weight: 400;
$forms-input-padding-block: .5rem;
$forms-input-padding-inline: .5rem;
$forms-input-stack-spacing: .25rem;
$forms-input-width-maximum: 70ch;
$forms-label-color: #000;
$forms-label-font-size-small: 0.875rem;
$forms-label-font-size: 1rem;
$forms-label-font-size-small: 0.875rem;
$forms-label-font-weight: 700;
$forms-label-inline-font-weight: 400;
$forms-line-height: 1.4;
$forms-message-color-error: #c40606;
$forms-message-icon-color-error: #c40606;
$forms-message-icon-color-resting: #000;
$forms-message-icon-size: 1rem;
$forms-pictographic-radio-checked-icon-background: #01324b;
$forms-pictographic-radio-checked-icon-border: #fff;
$forms-pictographic-radio-checked-icon-fill: #01324b;
$forms-pictographic-radio-checked-icon-stroke: #fff;
$forms-pictographic-radio-icon-size: 4rem;
$forms-pictographic-radio-spacing: 1rem;
$forms-pictographic-radio-unchecked-icon-background: #fff;
$forms-pictographic-radio-unchecked-icon-border: #999;
$forms-pictographic-radio-unchecked-icon-fill: #fff;
$forms-pictographic-radio-unchecked-icon-stroke: #01324b;
