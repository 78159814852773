.app-page-layout {
	display: flex;
	flex-wrap: wrap;
	word-wrap: break-word;
	overflow-wrap: anywhere;
}

.app-page-layout > * {
	margin: 0;
}

.app-page-layout__main,
.app-page-layout__side,
.app-page-layout__main > * {
	margin-bottom: spacing(48);
}

.app-page-layout__main--s {
	margin-top: spacing(16);
}

.app-page-layout__main--s > * {
	margin-bottom: spacing(16);
}

//TO DO: Add PR to EDS so the last eds-c-card-composable don't have margin bottom
.app-page-layout__main--s .eds-c-card-composable:last-of-type {
	margin-bottom: 0;
}

.app-page-layout__side > * {
	margin-bottom: spacing(24);
}

.app-page-layout__main > *:last-child,
.app-page-layout__side > *:last-child {
	margin-bottom: 0;
}

.app-page-layout__main.app-page-layout__main--mb-0 {
	margin-bottom: spacing(24);
}


.app-page-layout__main.app-page-layout__main--mb-0 ~
.app-page-layout__side,
.app-page-layout__main.app-page-layout__main--mb-0 > * {
	margin-bottom: 0;
}

.app-page-layout__main {
	flex-grow: 1;
}

.app-page-layout__side {
	width: 100%;
	flex-basis: 100%;
}

.app-page-layout--navigation {
	justify-content: space-between;
}

.app-page-layout__divider {
	border-bottom: 1px solid $t-l-color-blue-300;
}

// Temporary fix to display buttons in journal cards in collections page
.app-page-layout-buttons {
	min-width: initial;
	padding: 0 16px 16px;
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
}

@include from-tablet {
	.app-page-layout-buttons {
		min-width: 400px;
		flex-wrap: nowrap;
		flex-basis: 0;
		flex-grow: 999;
	}
}

@include from-desktop {
	.app-page-layout {
		justify-content: space-between;
	}

	.app-page-layout__main,
	.app-page-layout__side,
	.app-page-layout__main > * {
		margin-bottom: spacing(64);
	}

	.app-page-layout__main--s {
		margin-top: 0;
	}

	.app-page-layout__main--s > * {
		margin-bottom: spacing(24);
	}

	.app-page-layout__side {
		flex-basis: 300px;
	}

	.app-page-layout .app-page-layout__side {
		flex-grow: initial;
	}

	.app-page-layout > :not(.app-page-layout__side) {
		flex-basis: 63%;
		flex-grow: initial;
	}

	.app-page-layout--listing-content > :not(.app-page-layout__side) {
		flex-basis: 0;
		flex-grow: 999;
	}

	.app-page-layout--listing-content > :first-child {
		padding-right: spacing(24);
	}
}
