$refine-by__font-size: 16px;

.c-refine-by {
	font-size: $refine-by__font-size;
	border: 0;
	margin: 0;
	padding: 0;
}

.c-refine-by__layout {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	gap: spacing(16) spacing(24);

	> .c-forms__label--wrapper {
		margin-top: 0;
	}
}

@include from-tablet {
	.c-refine-by__layout {
		flex-direction: row;
	}
}

.c-refine-by__legend {
	font-weight: 700;
	margin-bottom: spacing(16);
	white-space: nowrap;
}

@include from-tablet {
	.c-refine-by__legend  {
		float: left;
		margin-right: spacing(16);
		margin-bottom: 0;
	}
}
