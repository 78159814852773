$card-collection--author-font-size: $t-a-typography-font-size-s;
$card-collection--background-color: $t-a-palette-default-blue-lighter;
$card-collection--border-color: #3c9cd7;
$card-collection--border-radius: 8px;
$card-collection--border-width-style: 1px solid;
$card-collection--border: $card-collection--border-width-style #3c9cd7;
$card-collection--content-font-size: $t-a-typography-font-size-xs;
$card-collection--content-line-height: 1.7;
$card-collection--default--title-color: $t-a-palette-default-black;
$card-collection--font-family: $t-a-typography-font-family-default;
$card-collection--heading-font-size: $t-a-typography-font-size-md;
$card-collection--heading-line-height: $t-a-typography-line-height-base;
$card-collection--heading-link-line-height:$t-a-typography-line-height-md;
$card-collection--heading-margin-bottom: $t-a-space-absolute-200;
$card-collection--highlight-border-width-style: 10px solid;
$card-collection--image-border: $card-collection--border-width-style #cfd8dc;
$card-collection--spacing: $t-a-space-absolute-400;


.app-card-collection {
	position: relative;
	display: flex;
	font-family: $card-collection--font-family;
	background-color: #fff;
	padding: $card-collection--spacing;
	border: 1px solid $t-a-palette-default-blue-light;
	border-top: $card-collection--highlight-border-width-style $card-collection--border-color;
	border-radius: $card-collection--border-radius;
	overflow: hidden;
	flex-direction: column;
	justify-content: space-between;
}

.app-card-collection__main {
	display: flex;
	flex: 1 1 auto;
	word-break: break-word;
	font-size: $card-collection--content-font-size;
	padding-bottom: $card-collection--spacing;
}

.app-card-collection__main + .app-card-collection__side {
	border-top: 1px solid $t-a-palette-default-blue-light;
}

.app-card-collection__side {
	padding-top: $card-collection--spacing;
}

.app-card-collection__content {
	font-weight: $t-a-typography-font-weight-normal;
}

.app-card-collection__text {
	line-height: $card-collection--content-line-height;
}

.app-card-collection__text p {
	margin: 0;
}

.app-card-collection__heading {
	margin-top: 0;
	margin-bottom: $card-collection--heading-margin-bottom;
	font-size: $card-collection--heading-font-size;
}

.app-card-collection__heading a {
	@include u-link-faux-block;
	color: $card-collection--default--title-color;
	line-height: $card-collection--heading-link-line-height;
}

.app-card-collection__description-list {
	margin: 0;
	font-size: $t-a-typography-font-size-xs;
	margin-bottom: $t-a-space-absolute-400;
}

.app-card-collection__description-list:last-of-type {
	margin-bottom: 0;
}

.app-card-collection__description-list dd {
	font-weight: $t-a-typography-font-weight-bold;
	margin: 0;
}

.app-card-collection__status-message {
	margin-top: $t-a-space-absolute-400;
}

.app-card-collection__status-message svg {
	fill: $t-a-palette-primary-medium-blue;
	// TODO: Remove this override once status message component updated: https://github.com/springernature/eds/pull/263
	margin-right: $t-a-space-absolute-200;
}

@include from-wide-tablet {
	.app-card-collection {
		flex-direction: row;
		border-left: $card-collection--highlight-border-width-style $card-collection--border-color;
		border-top: 1px solid $t-a-palette-default-blue-light;
	}

	.app-card-collection--stack {
		flex-direction: column;
		border-left: 1px solid $t-a-palette-default-blue-light;
		border-top: $card-collection--highlight-border-width-style $card-collection--border-color;
	}

	.app-card-collection__main {
		padding-right: $card-collection--spacing;
		padding-bottom: 0;
	}

	.app-card-collection__main + .app-card-collection__side {
		border-left: 1px solid $t-a-palette-default-blue-light;
		border-top: none;
	}

	.app-card-collection__side {
		min-width: 240px;
		margin-bottom: 0;
		padding-left: $card-collection--spacing;
		padding-top: 0;
	}
}
