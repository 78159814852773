// This uses a strange combination of flat: right and line-height: $app-journal-nav-sidebar--icon-container-size instead of using flexbox. This is because our base link styling that uses text-decoration-thickness does not work correctly on Safari v15 and Safari v16 if the parent is flex

$app-journal-nav-sidebar--icon-background-color: #eff6fb;
$app-journal-nav-sidebar--icon-border-radius: 50%;
$app-journal-nav-sidebar--icon-container-size: 3em;
$app-journal-nav-sidebar--icon-size: 18px;
$app-journal-nav-sidebar--border-color: $t-a-palette-default-blue-light;
$app-journal-nav-sidebar--border-radius: 8px;

.app-journal-nav-sidebar {
	padding: 0 spacing(16);
	border: 1px solid $app-journal-nav-sidebar--border-color;
	border-radius: $app-journal-nav-sidebar--border-radius;
	font-size: $t-a-typography-font-size-xs;
}

.app-journal-nav-sidebar__list {
	@include u-list-reset;
}

.app-journal-nav-sidebar__item {
	padding: spacing(16) 0;
	border-bottom: 1px solid $app-journal-nav-sidebar--border-color;
}

.app-journal-nav-sidebar__item:last-child {
	border-bottom: none;
}

.app-journal-nav-sidebar__icon {
	display: inline-block;
	width: $app-journal-nav-sidebar--icon-size;
	height: $app-journal-nav-sidebar--icon-size;
}

.app-journal-nav-sidebar__side {
	float: right;
}

.app-journal-nav-sidebar__icon-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: $app-journal-nav-sidebar--icon-container-size;
	height: $app-journal-nav-sidebar--icon-container-size;
	background-color: $app-journal-nav-sidebar--icon-background-color;
	border-radius: $app-journal-nav-sidebar--icon-border-radius;
}


.app-journal-nav-sidebar__link {
	display: block;
	color: color('black');
}

.app-journal-nav-sidebar__link-text {
	line-height: $app-journal-nav-sidebar--icon-container-size;
}

.app-journal-nav-sidebar__link:visited {
	color: color('black');
}
